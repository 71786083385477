import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/smile-again.scss"

const SmileAgainProgram = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 2000
  }
  return (
    <Layout>
      <SEO
        title="Smile Again Program"
        description="It is time for a brand new, healthy, beautiful smile at  Shawn B. Davis, DMD, Oral & Facial Surgery Institute."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/UTOS/DEV/smile-again-hero-banner"
            alt="smile again banner"
          />
          {/* <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/UTOS/DEV/smile-again-hero-banner"
            alt="smile again banner"
          /> */}
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity to Smile Again</h1>

            <p>
              The Shawn B. Davis, DMD Oral & Facial Surgery Institute team is
              proud to announce that this year’s Smile Again recipient is Andrew
              from Hurricane!
            </p>

            <p>
              Andrew, 43, is a self-employed delivery driver and contractor who
              has faced significant medical challenges in his life. At 28, a
              pharmaceutical injury from a severe asthma attack led to the loss
              of both hips and 12 teeth, including his front teeth, as part of
              the surgery process. This event, along with self-medication,
              caused Andrew to lose his confidence and his smile. After years of
              struggling, Andrew is now sober and rebuilding his life. He wants
              to smile again, not only for himself but for his family, so they
              can create lasting memories with him smiling proudly in photos. He
              believes that restoring his smile will allow him to fully express
              his happiness and pride in his family.
            </p>

            <p>
              We hope that providing Andrew with the opportunity to smile again
              will help him regain his confidence and continue on his path of
              personal transformation for the sake of his family and his future.{" "}
            </p>

            <h2 style={{ fontSize: "24px" }}>Full-Arch Restoration</h2>
            <p>
              Andrew will receive a full set of customized, artificial teeth at
              no cost. This tooth replacement solution is called full-arch
              restoration because it replaces a full dental arch of missing or
              failing teeth. Our team of experts will work closely with a
              restorative dentist to complete the treatment.
            </p>
          </div>
        </div>

        {/* <div className="sas__section">
          <div className="sas__container small">
            <Slider {...settings}>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-1"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-2"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-3"
                  alt="program recipient"
                />
              </div>
              <div>
                <video
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  controls>
                  <source
                    src="https://res.cloudinary.com/nuvolum/video/upload/v1699992734/CIOS/DEV/smile-again-recipient-4.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Slider>
          </div>
        </div> */}

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/oralfacialsurgeryinstitute/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/ofsistgeorge/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              to follow Mathew’s journey to a new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-st-george-ut/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
